import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import ProjectList from '../components/ProjectList'
import config, { device } from '../styles/config'

const HeaderContainer = styled.div`
  margin: 0 auto;
  margin-top: 8.33rem;
  margin-bottom: 11.11rem;
  width: 100%;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const Header = styled(Col)`
  /* font-family: 'Lyno Jean'; */
  font-size: 2.77rem;
  line-height: 1.413;
  letter-spacing: 0.62px;
  font-weight: 300;
  text-align: center;
  text-wrap: balance;
  
  @media ${ device.sm } {
    font-size: 2rem;
    margin-bottom: 2.7rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const HomePage = ({ data: { datoCmsHomepage: { headerEnglish, headerFrench } } }) => {
  return (
    <>
      <HeaderContainer>
        <Row>
          <Header xs={8}>{headerEnglish}</Header>
          <Header xs={8}>{headerFrench}</Header>
        </Row>
      </HeaderContainer>
      <ProjectList/>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    datoCmsHomepage {
      headerEnglish
      headerFrench
    }
  }
`

export default HomePage
